/* eslint-disable */
import $ from 'jquery';

$(function () {
    /**
     * Is this browser supported by Tripletex?
     *
     * By October the 1st 2019 we will no longer support Internet Explorer. But, we would like to avoid thinking about
     * browsers and browser versions, and rather think about what features the browsers we support must have implemented.
     *
     * As of October 1st 2019, browser must support:
     *
     * * ES6 features: https://caniuse.com/#feat=es6
     * * And everything below :-)
     */
    function isSupported() {
        // Supporting the history API is a must for navigating within Tripletex.
        if (!window.history || !history.pushState) {
            return false;
        }

        /**
         * To see if the browser supports ES6, we need to evaluate expressions that have "wrong" syntax in older (non ES6)
         * browsers. Therefore we need to evaluate the expression within an eval.
         *
         * This eval statement is private inside this isSupported function, not exposed or used outside. I.e. this eval
         * expression is totally safe to use.
         *
         * @param statement an ES6 statmment
         * @returns {boolean} true, if the statement doesn't cause a syntax error.
         */
        function evaluates(statement) {
            try {
                eval(statement);
                return true;
            } catch (e) {
                return false;
            }
        }

        var expressions = [
            'class S {}',
            'var f = x => 1',
            'let a = 1',
            'const a = 1',
            'function* t() {}', // Generator
            'var a = `a`', // Template strings
            'function t(o = 1) {}', // Defaults
            'async function t() {}',
            'const a = {}; a?.b', // Optional chaining
            'const a = null; a ?? `c`', // Nullish coalescing operator
        ];

        return expressions.every(function (exp) {
            return evaluates(exp);
        });
    }

    if (!isSupported()) {
        $('#unsupportedBrowserAlert').removeClass('atl-hidden');
        $('#loginButton').prop('disabled', true);
    }

    function isMobileReg() {
        var a = navigator.userAgent || navigator.vendor || window.opera;
        return (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                a.substr(0, 4)
            )
        );
    }

    function isTouchDevice() {
        return 'ontouchstart' in window || 'onmsgesturechange' in window; // ie10 fix
    }

    function isNarrowScreen() {
        return (
            isMobileReg() &&
            isTouchDevice() &&
            (window.screen.width < 768 || window.screen.height < 768)
        );
    }

    window.setTimeout(function () {
        // iPhone reports false width values before document is ready.
        document.getElementById('narrowScreen').value = isNarrowScreen();
    }, 500);
});
